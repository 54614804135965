/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

const Layout = ({ children, redirectTo }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          name
          description
          author
          avatar
          email
          github
          linkedin
          rss
          twitter
          youtube
          url
          baseurl
        }
      }
    }
  `)
  const {
    title,
    name,
    description,
    author,
    avatar,
    email,
    github,
    linkedin,
    rss,
    twitter,
    youtube,
    url,
    baseurl
  } = data.site.siteMetadata;

  if(typeof window !== "undefined")
    if(redirectTo){
      window.location = redirectTo
      return
    }

  return (
    <>
      <div className="wrapper-masthead">
        <div className="container">
          <header className="masthead clearfix">
            <Link to={'/'} className="site-avatar"><img loading='lazy' src={avatar} width={70} height={70} alt="Nick McCullum Headshot" /></Link>
            <div className="site-info">
              <h1 className="site-name"><Link to={'/'}>{name}</Link></h1>
              <p className="site-description">{description}</p>
            </div>
            <nav>
              <Link to={'/'}>Start Here</Link>
              <a href="https://courses.nickmccullum.com/">Courses</a>
              <Link to={`/blog/`}>Blog</Link>
            </nav>
          </header>
        </div>
      </div>

      <div id="main" role="main" className="container">
        {children}
      </div>

      <div className="wrapper-footer">
        <div className="container">
          <footer className="footer">
            {email && <a href={`mailto:${email}`}><i className="svg-icon email"></i></a>}
            {github && <a href={`https://github.com/${github}`}><i className="svg-icon github"></i></a>}
            {linkedin && <a href={`https://www.linkedin.com/in/${linkedin}`}><i className="svg-icon linkedin"></i></a>}
            {rss && <a href={`/feed.xml`}><i className="svg-icon rss"></i></a>}
            {twitter && <a href={`https://www.twitter.com/${twitter}`}><i className="svg-icon twitter"></i></a>}
            {youtube && <a href={`https://youtube.com/${youtube}`}><i className="svg-icon youtube"></i></a>}
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
